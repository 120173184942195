import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {SearchResultsSidebarStyled, SidebarItemLink, SidebarItemsStyled, SidebarItemStyled} from './styles';
import {kebabCase} from '../../../../../utils/memoized';
import {scrollWindowUp} from '../../../../../utils/scrollUtils';

const scrollElementIntoViewUsingKebabCasedId = rawId => {
	// temporary solution
	const domId = kebabCase(rawId);
	scrollWindowUp(domId);
};

const SearchResultsSidebar = ({pairsTitlesResultsCount}) => (
	<SearchResultsSidebarStyled>
		<SidebarItemsStyled>
			{pairsTitlesResultsCount.map(([title, count]) => {
				// pass href and onClick only when count > 0
				const propsForItemLink = count
					? {
							onClick: () => scrollElementIntoViewUsingKebabCasedId(title),
							isSearchAnchorLink: true
					  }
					: {};
				return (
					<SidebarItemStyled key={title}>
						<SidebarItemLink {...propsForItemLink}>
							{title} ({count})
						</SidebarItemLink>
					</SidebarItemStyled>
				);
			})}
		</SidebarItemsStyled>
	</SearchResultsSidebarStyled>
);

SearchResultsSidebar.propTypes = {
	pairsTitlesResultsCount: PropTypes.oneOfType([
		PropTypes.shape({
			title: PropTypes.string,
			resultsCount: PropTypes.number
		}),
		PropTypes.array
	]).isRequired
};

SearchResultsSidebar.defaultProps = {};

// redux

const mapStateToProps = state => {
	const results = state.fullSearch.results || {};

	return {
		pairsTitlesResultsCount: Object.entries(results).map(([title, {resultsCount}]) => [title, resultsCount])
	};
};

/** @component */
export default connect(mapStateToProps)(SearchResultsSidebar);
