import React from 'react';
import PropTypes from 'prop-types';
import {ColumnStyled} from './styles';

const Column = (props) => {
	const {children, xxs, xs, sm, md, lg, xl, xxl, offset, className, id} = props;
	const columnProps = {
		xxs,
		xs,
		sm,
		md,
		lg,
		xl,
		xxl,
		offset,
		className,
		id,
	};
	return <ColumnStyled {...columnProps}>{children}</ColumnStyled>;
};

Column.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	xxs: PropTypes.number,
	xs: PropTypes.number,
	sm: PropTypes.number,
	md: PropTypes.number,
	lg: PropTypes.number,
	xl: PropTypes.number,
	xxl: PropTypes.number,
	offset: PropTypes.shape({
		xxs: PropTypes.number,
		xs: PropTypes.number,
		sm: PropTypes.number,
		md: PropTypes.number,
		lg: PropTypes.number,
		xl: PropTypes.number,
		xxl: PropTypes.number,
	}),
	className: PropTypes.string,
	id: PropTypes.string,
};

Column.defaultProps = {
	children: null,
	xxs: null,
	xs: null,
	sm: null,
	md: null,
	lg: null,
	xl: null,
	xxl: null,
	offset: {
		xxs: null,
		xs: null,
		sm: null,
		md: null,
		lg: null,
		xl: null,
		xxl: null,
	},
	className: null,
	id: null,
};

export default Column;
