import React from 'react';
import PropTypes from 'prop-types';
import {lookupComponentTypeForTitle} from './SearchResultEntry';

export const SearchResultFacet = ({list, title}) => {
	const EntryComponent = lookupComponentTypeForTitle(title);

	return (
		/* Show first four results only */
		<ul>
			{list.slice(0, 4).map((elem, index) => (
				<li key={elem.url}>
					<EntryComponent position={index + 1} {...elem} />
				</li>
			))}
		</ul>
	);
};

SearchResultFacet.propTypes = {
	list: PropTypes.arrayOf(
		PropTypes.shape({
			content: PropTypes.string,
			position: PropTypes.number,
			tags: PropTypes.arrayOf(PropTypes.string),
			thumbnail: PropTypes.string,
			title: PropTypes.string,
			url: PropTypes.string
		})
	),
	title: PropTypes.string
};

SearchResultFacet.defaultProps = {
	list: [],
	title: ''
};
