import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import {ErrorPanel} from '../components/ErrorPanel';
import SearchResultFacets from './components/SearchResultFacets';
import NoSearchResultPanel from '../components/NoSearchResultPanel';
import Row from '../../../Layout/Grid/Row';
import Column from '../../../Layout/Grid/Column';
import Section from '../../../Layout/Section/Section';
import {FullSearchStyled} from '../FullSearch/styles';
import SearchFormContainer from './components/SearchFormContainer';
import LoadingSpinner from '../../../Elements/LoadingSpinner/LoadingSpinner';

class InitialSearch extends React.PureComponent {
	renderErrorPanel() {
		const {loading, error} = this.props;
		return !(error instanceof TypeError) && error && !loading ? <ErrorPanel error={error} /> : null;
	}

	renderLoadingPanel() {
		const {loading} = this.props;
		return loading ? (
			<div>
				<LoadingSpinner />
			</div>
		) : null;
	}

	renderResultPanel() {
		const {loading, results, httpQueries, count} = this.props;
		if (loading || _.isNil(results)) {
			return null;
		}
		return (
			/* prettier-ignore */
			_.isEmpty(results)
				? <NoSearchResultPanel />
				: <SearchResultFacets entries={results} httpQueries={httpQueries} count={count}/>
		);
	}

	render() {
		return (
			<FullSearchStyled>
				<Section showPadding>
					<Row>
						<Column>
							<SearchFormContainer isOpen isPermanent />
							{this.renderLoadingPanel()}
							{this.renderErrorPanel()}
							{this.renderResultPanel()}
						</Column>
					</Row>
				</Section>
			</FullSearchStyled>
		);
	}
}

InitialSearch.propTypes = {
	error: PropTypes.string,
	loading: PropTypes.bool,
	results: PropTypes.objectOf(PropTypes.object),
	httpQueries: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	count: PropTypes.number,
};
InitialSearch.defaultProps = {
	error: null,
	loading: false,
	results: null,
	httpQueries: null,
	count: undefined,
};

const mapStateToProps = (state) => state.initialSearch; // == {error, loading, results}

export default connect(mapStateToProps)(InitialSearch);
