import styled, {css} from 'styled-components';
import {Link} from 'gatsby';
import {HeadlineStyledH5} from '../Headline/styles';
import {colors, sizes} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';
import Image from '../Image/Image';

export const appearances = ['default', 'shadow', 'relatedContent'];

const TeaserShadowStyles = css`
	box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);

	${media.lg`
		box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
	`};
`;
const TeaserRelatedContentStyles = css`
	color: ${colors.white};
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;

	> * {
		flex: 0 1 auto;
	}

	&::before {
		display: none;
	}
`;

const TeaserImageStyles = css`
	box-sizing: border-box;
	height: 280px;
	transition: transform 0.5s ease;
	transform: scale(1.2) rotate(2deg);
	transform-origin: 50% 50%;

	${media.xs`
		height: 280px;
	`}

	${media.md`
		height: 400px;
	`};
`;

export const ServiceTeaserImageStyled = styled(Image)`
	${TeaserImageStyles};

	${media.lg`
		margin-bottom: 5em;
	`};
`;

export const TeaserImageStyled = styled(Image)`
	${TeaserImageStyles};
`;

export const TeaserStyled = styled(Link)`
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: flex-start;
	height: 100%;
	flex-direction: column;
	box-sizing: border-box;
	background-color: ${colors.white};

	text-decoration: none;
	color: ${colors.black};
	${(props) => (props.item.appearance === 'shadow' ? TeaserShadowStyles : null)};
	transition: transform 0.3s ease;
	overflow: hidden;

	&:hover {
		${TeaserImageStyled},
		${ServiceTeaserImageStyled} {
			transform: scale(1) rotate(0deg);
		}
	}
`;

export const ServiceTeaserSubtitleStyled = styled.div`
	display: block;
	text-align: center;
	font-size: 0.8em;
	font-weight: 400;
	margin-top: 0.5em;
	transition: font-size 0.3s ease-out, opacity 0.3s ease-out;
	color: ${colors.black};

	${media.sm`
	font-size: 0.9em;
	`}

	${media.md`
		font-size: 0.2em;
		opacity: 0;
	`};

	${() => TeaserStyled}:hover & {
		font-size: 0.9em;
		opacity: 1;
		transition: font-size 0.3s ease-out, opacity 0.3s ease-out;
	}
`;

export const ServiceTeaserLabelStyled = styled.div`
	display: block;
	text-transform: uppercase;
	color: ${colors.navy};
	text-align: center;
	opacity: 0.8;
	font-size: 0.8em;
	margin-bottom: 0.5em;

	${media.md`
		font-size: 0.9em;
	`}
`;

const TeaserTitleStyles = css`
	text-align: center;
	font-size: 0.9em;
	width: 100%;

	${media.md`
		font-size: 1em;
	`};

	${media.lg`
		font-size: 1.1em;
	`};

	// sadly have to boost specificity :(
	&& {
		margin-bottom: 0;
	}

	${() => TeaserStyled}:hover & {
		color: ${colors.black};
	}
`;

export const ServiceTeaserTitleStyled = styled(HeadlineStyledH5)`
	${TeaserTitleStyles};
`;
export const TeaserTitleStyled = styled(HeadlineStyledH5)`
	${TeaserTitleStyles};

	${() => TeaserStyled}:hover & {
		color: ${colors.white};
	}
`;

export const ServiceTeaserTextStyles = css`
	margin-top: ${withUnit(sizes.distance.base / 2)};
`;

export const ServiceTeaserTextStyled = styled.div`
	${ServiceTeaserTextStyles};
`;

export const TeaserTextStyled = styled.div`
	${ServiceTeaserTextStyles};
`;

const TeaserContentStyles = css`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 101%;
	height: 100px;
	box-sizing: border-box;
	padding: ${withUnit(sizes.distance.base / 4)};
	z-index: 1;

	${media.sm`
		padding: ${withUnit(sizes.distance.base / 2)};
		
	`}

	${media.md`
		height: unset;
		padding: ${withUnit(sizes.distance.base)};
	`};

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 110%;
		height: 110%;
		background-color: ${colors.white};
		transform: translate(-50%, -50%);
		transition: transform 0.5s ease;
		z-index: -1;
	}

	${(props) => (props.appearance === 'relatedContent' ? TeaserRelatedContentStyles : null)};
`;

export const ServiceTeaserContentStyled = styled.div`
	${TeaserContentStyles};
	height: 50%;
	padding: ${withUnit(sizes.distance.base / 3)};
	position: relative;

	${media.md`
		position: absolute;
	`};
`;

export const TeaserContentStyled = styled.div`
	${TeaserContentStyles};

	${() => TeaserStyled}:hover &::before {
		transform: translate(-50%, 100%);
	}
`;
