import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {LinkToAllSearchResultsStyled} from '../styles';
import Button from '../../../../Elements/Button/Button';
import {fullSearch} from '../../../../../state/actions/search';

const LinkToAllSearchResults = ({onClick, count}) => (
	<LinkToAllSearchResultsStyled onClick={onClick}>
		<Button text={`Zeige alle ${count} Ergebnisse an`} />
	</LinkToAllSearchResultsStyled>
);

LinkToAllSearchResults.propTypes = {
	onClick: PropTypes.func.isRequired,
	count: PropTypes.number.isRequired,
};

// connect with redux

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
	onClick: () => dispatch(fullSearch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkToAllSearchResults);
