import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Section from '../../../../Layout/Section/Section';
import Headline from '../../../../Elements/Headline/Headline';
import {FullSearchResultsStyled, LinkStyled} from './styles';
import Row from '../../../../Layout/Grid/Row';
import Column from '../../../../Layout/Grid/Column';
import ArticleTeaser from '../../../Article/ArticleTeaser/ArticleTeaser';
import PostList from '../../../Stream/PostList/Container';
import NoSearchResultPanel from '../../components/NoSearchResultPanel';
import {extractPathFromUrl} from '../../../../../utils/urls';
import {kebabCase} from '../../../../../utils/memoized';

// used in <MultipleSkleroseItem>
// todo: find a better name
const createItem = (searchResultEntry) => {
	const {content, title, url} = searchResultEntry;
	const slug = extractPathFromUrl(url);

	const result = {
		link: {
			title: 'slug',
			url: slug,
			target: '',
		},
		text: content,
		title,
		type: 'search',
	};
	return result;
};

const createPost = (searchResultEntry) => {
	const {
		content,
		tags: [firstTag],
		thumbnail,
		title,
		url,
	} = searchResultEntry;

	const slug = extractPathFromUrl(url);

	const result = {
		node: {
			title,
			excerpt: content,
			primary_category: {
				name: firstTag, // TODO correct?
			},
			acf: {
				hidden: null,
			},
			categories: [
				{
					name: firstTag, // TODO correct?
				},
			],
			slug,
			featured_media: {
				localFile: {
					childImageSharp: {
						fluid: {
							src: thumbnail,
						},
					},
				},
			},
		},
	};
	return result;
};

// local components

const MultipleSkleroseItem = ({searchResultEntry, position}) => {
	const data = createItem(searchResultEntry);
	return (
		<LinkStyled to={data.link.url}>
			<ArticleTeaser position={position} item={data} />
		</LinkStyled>
	);
};

MultipleSkleroseItem.propTypes = {
	searchResultEntry: PropTypes.shape({
		content: PropTypes.string,
		position: PropTypes.number,
		tags: PropTypes.arrayOf(PropTypes.string),
		thumbnail: PropTypes.string,
		title: PropTypes.string,
		url: PropTypes.string,
	}).isRequired,
};

// component

class SearchResultsBody extends React.PureComponent {
	static propTypes = {
		results: PropTypes.shape({
			title: PropTypes.string,
			resultsCount: PropTypes.number,
		}),
	};

	static defaultProps = {
		results: [],
	};

	/* eslint-disable-next-line class-methods-use-this */
	renderHeadlineOfSegment(title) {
		const titleAsId = kebabCase(title); // needed to identify the 'target' for scrolling
		return <Headline as="h3" text={title} id={titleAsId} />;
	}

	renderMultipleSklerose(title, entries) {
		return (
			<>
				{this.renderHeadlineOfSegment(title)}
				<FullSearchResultsStyled>
					<Row>
						{(entries || []).map((entry) => (
							<Column sm={6} lg={4}>
								<MultipleSkleroseItem searchResultEntry={entry} />
							</Column>
						))}
					</Row>
				</FullSearchResultsStyled>
			</>
		);
	}

	renderService(title, entries) {
		return (
			<>
				{this.renderHeadlineOfSegment(title)}
				<FullSearchResultsStyled>
					<Row>
						{(entries || []).map((entry, index) => (
							<Column sm={6} lg={4}>
								<MultipleSkleroseItem position={index + 1} searchResultEntry={entry} />
							</Column>
						))}
					</Row>
				</FullSearchResultsStyled>
			</>
		);
	}

	renderLebenMitMS(title, entries) {
		return (
			<div className="ap-search-postlist">
				{this.renderHeadlineOfSegment(title)}
				<PostList posts={entries.map(createPost)} title={null} section="searchTeaserList" />
			</div>
		);
	}

	renderResultFacet(title, data) {
		const {urls} = data; // these are not url-strings, but a list of objects coming from solr named 'urls'
		if (urls && urls.length) {
			switch (title) {
				case 'Leben mit MS':
					return this.renderLebenMitMS(title, urls);
				case 'Multiple Sklerose':
					return this.renderMultipleSklerose(title, urls);
				case 'Service':
					return this.renderService(title, urls);
				default:
					// for the time being:
					return null;
			}
		} else {
			return null;
		}
	}

	renderResults() {
		const {results} = this.props;
		return Object.entries(results).map(([title, data]) => this.renderResultFacet(title, data));
	}

	render() {
		const {results} = this.props;
		const resultsAreEmpty = !_.find(Object.values(results), (data) => data.resultsCount > 0);
		return <Section showPadding>{resultsAreEmpty ? <NoSearchResultPanel /> : this.renderResults()}</Section>;
	}
}

// redux

const mapStateToProps = (state) => ({
	results: state.fullSearch.results,
});

export default connect(mapStateToProps)(SearchResultsBody);
