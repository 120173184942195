import React from 'react';
import PropTypes from 'prop-types';
import {RowStyled} from './styles';

const Row = (props) => {
	const {children, styles} = props;
	return <RowStyled styles={styles}>{children}</RowStyled>;
};

Row.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	styles: PropTypes.shape({
		alignItems: PropTypes.string,
		justifyContent: PropTypes.string,
		marginBottom: PropTypes.bool,
		padding: PropTypes.string,
	}),
};

Row.defaultProps = {
	children: null,
	styles: null,
};

/** @component */
export default Row;
