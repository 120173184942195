import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import {FullSearchStyled} from './styles';
import SearchFormContainer from './components/SearchFormContainer';
import SearchResultsSidebar from './components/SearchResultsSidebar';
import Column from '../../../Layout/Grid/Column';
import Row from '../../../Layout/Grid/Row';
import Section from '../../../Layout/Section/Section';
import SearchResultsBody from './components/SearchResultsBody';

// @component

const FullSearch = ({currentFacet}) => (
	<FullSearchStyled>
		<Section showPadding className='stickArea'>
			<Row>
				<Column lg={3} xxl={2}>
					<Sticky
						topOffset={-100}
						bottomOffset={80}
						boundaryElement=".stickArea"
						hideOnBoundaryHit={false}
						stickyStyle={{marginTop: '100px'}}>
						<SearchResultsSidebar currentFacet={currentFacet} />
					</Sticky>
				</Column>
				<Column lg={9} xxl={10}>
					<SearchFormContainer isOpen isPermanent />
					<SearchResultsBody />
				</Column>
			</Row>
		</Section>
	</FullSearchStyled>
);

FullSearch.propTypes = {
	currentFacet: PropTypes.element,
};

FullSearch.defaultProps = {
	currentFacet: null,
};

/** @component */
export default FullSearch;
