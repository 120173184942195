import React from 'react';
import PropTypes from 'prop-types';

export const ErrorPanel = ({error}) => {
	if (typeof error === 'string') {
		return <div> Fehler: </div>;
	}

	return <div>Ein unbekannter Fehler ist aufgetreten.</div>;
};

ErrorPanel.propTypes = {
	error: PropTypes.string.isRequired
};
