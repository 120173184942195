import React from 'react';
import PropTypes from 'prop-types';
import {
	TeaserStyled,
	TeaserImageStyled,
	TeaserTitleStyled,
	TeaserContentStyled,
	TeaserTextStyled,
	appearances
} from './styles';
import Label from '../Label/Label';

import Placeholder from '../../../assets/img/img_cleo.jpg';
import ImpressionClickTrackerHOC from '../../Container/ImpressionClickTracker/ImpressionClickTracker';

const Teaser = props => {
	const {item, position} = props;
	const imageProps = {type: 'teaser', appearance: item.appearance};
	const teaserProps = {appearance: item.appearance};
	const label = item.label ? <Label text={item.label} /> : null;
	const title = item.title.replace(/_/g, " ");
	const text =
		item.text !== '' && item.text !== undefined ? (
			<TeaserTextStyled {...teaserProps} dangerouslySetInnerHTML={{__html: item.text}} />
		) : null;
	return (
		<ImpressionClickTrackerHOC>
			<TeaserStyled
				data-teaser-type="ContentTeaser"
				data-teaser-label={label}
				data-teaser-title={item.title}
				data-teaser-link={item.link.url}
				data-teaser-position={position}
				to={item.link.url}
				className="ap-teaser"
				{...props}>
				<TeaserImageStyled
					src={item.image.localFile.publicURL || Placeholder}
					alt={item.image.alt_text}
					title={item.image.title}
					{...imageProps}
				/>
				<TeaserContentStyled {...teaserProps}>
					{label}
					<TeaserTitleStyled {...teaserProps} dangerouslySetInnerHTML={{__html: title}} />
					{text}
				</TeaserContentStyled>
			</TeaserStyled>
		</ImpressionClickTrackerHOC>
	);
};

Teaser.propTypes = {
	position: PropTypes.number,
	item: PropTypes.shape({
		appearance: PropTypes.oneOf(appearances),
		label: PropTypes.string,
		title: PropTypes.string,
		text: PropTypes.string,
		onClick: PropTypes.func,
		link: PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string,
			target: PropTypes.string
		}),
		image: PropTypes.shape({
			source_url: PropTypes.string,
			alt_text: PropTypes.string,
			title: PropTypes.string,
			localFile: PropTypes.shape({
				publicURL: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.shape({
						srcSet: PropTypes.string,
						src: PropTypes.string
					})
				])
			})
		})
	})
};

Teaser.defaultProps = {
	position: null,
	item: {
		appearance: 'default',
		label: '',
		title: '',
		text: '',
		onClick: null,
		link: null,
		image: null
	}
};

/** @component */
export default Teaser;
