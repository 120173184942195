import React from 'react';
import {LoadingSpinnerStyled} from './styles';

const LoadingSpinner = () => {
	return (
		<LoadingSpinnerStyled>
			<div className="lds-ellipsis">
				<div />
				<div />
				<div />
				<div />
			</div>
		</LoadingSpinnerStyled>
	);
};

/** @component */
export default LoadingSpinner;
