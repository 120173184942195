import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import {
	ImageStyled,
	LabelType1Styled,
	LabelType2Styled,
	HeadlineType1Styled,
	HeadlineType2Styled,
	SearchResultEntryType1Styled,
	SearchResultEntryType2Styled,
	TeaserStyled
} from '../styles';
import Row from '../../../../Layout/Grid/Row';
import Column from '../../../../Layout/Grid/Column';
import Link from '../../../../Elements/Link/Link';
import {extractPathFromUrl} from '../../../../../utils/urls';
import Placeholder from '../../../../../assets/img/img_cleo.jpg';

export const SearchResultEntryTopTreffer = ({...props}) => (
	<StaticQuery
		query={graphql`
			query {
				allImageSharp {
					edges {
						node {
							fluid(quality: 50) {
								...GatsbyImageSharpFluid
								originalName
							}
						}
					}
				}
			}
		`}
		render={data => {
			const {title, url, thumbnail, position} = props;
			const imageName = thumbnail.substr(thumbnail.lastIndexOf('/') + 1);
			const image = data.allImageSharp.edges.find(edge => edge.node.fluid.originalName === imageName);
			const urlPath = extractPathFromUrl(url);
			let imageSource = thumbnail || '';
			if (image && image.node.fluid.src) {
				imageSource = image.node.fluid.src;
			}
			if (url.includes('/spezial/')) {
				return null;
			}
			return (
				<TeaserStyled
					position={position}
					item={{
						appearance: 'shadow',
						title,
						image: {
							localFile: {
								publicURL: imageSource
							}
						},
						link: {
							url: urlPath
						}
					}}
				/>
			);
		}}
	/>
);

SearchResultEntryTopTreffer.propTypes = {
	title: PropTypes.string,
	thumbnail: PropTypes.string,
	url: PropTypes.string,
	position: PropTypes.number
};

SearchResultEntryTopTreffer.defaultProps = {
	title: '',
	thumbnail: '',
	url: '',
	position: null
};

export const SearchResultEntryType1 = ({title, url, tags}) => {
	const primaryCategory = tags[0];
	const urlPath = extractPathFromUrl(url);
	return (
		<SearchResultEntryType1Styled>
			<Link to={urlPath}>
				<HeadlineType1Styled dangerouslySetInnerHTML={{__html: title}} />
				<LabelType1Styled>{primaryCategory}</LabelType1Styled>
			</Link>
		</SearchResultEntryType1Styled>
	);
};

SearchResultEntryType1.propTypes = {
	title: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.string),
	url: PropTypes.string
};

SearchResultEntryType1.defaultProps = {
	title: '',
	tags: [],
	url: ''
};

export const SearchResultEntryType2 = ({title, tags, thumbnail, url}) => {
	const primaryCategory = tags[0];
	const thumbnailElement = thumbnail ? (
		<ImageStyled src={thumbnail} alt={title} title={title} />
	) : (
		<ImageStyled src={Placeholder} alt={title} title={title} />
	);

	const urlPath = extractPathFromUrl(url);
	const styles = {
		alignItems: 'center'
	};
	return (
		<SearchResultEntryType2Styled>
			<Row styles={styles}>
				<Column xs={3}>
					<Link to={urlPath}>{thumbnailElement}</Link>
				</Column>
				<Column xs={9}>
					<Link to={urlPath}>
						<LabelType2Styled>{primaryCategory}</LabelType2Styled>
						<HeadlineType2Styled dangerouslySetInnerHTML={{__html: title}} />
					</Link>
				</Column>
			</Row>
		</SearchResultEntryType2Styled>
	);
};

SearchResultEntryType2.propTypes = {
	title: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.string),
	url: PropTypes.string,
	thumbnail: PropTypes.string
};

SearchResultEntryType2.defaultProps = {
	title: '',
	tags: [],
	url: '',
	thumbnail: ''
};

// lookup after title
export const lookupComponentTypeForTitle = title => {
	const lookupTable = {
		'Top-Treffer': SearchResultEntryTopTreffer,
		'Multiple Sklerose': SearchResultEntryType1,
		'Leben mit MS': SearchResultEntryType2,
		Service: SearchResultEntryType2
	};

	return lookupTable[title] || SearchResultEntryType2;
};
