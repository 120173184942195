import _ from 'lodash';

// memoize --> use internal cache for recurring input values

export const kebabCase = _.memoize(_.kebabCase);

// todo needs unit test or replace it with library function
export const arraysAreEqual = (arr1, arr2) => {
	// arr1 and arr2 may be null/undefined
	if (!arr1 || !arr2 || arr1.length !== arr2.length) {
		return false;
	}

	for (const i in arr1) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
};

// todo needs jsdoc
export const memoizeOne = func => {
	let lastArgs = null;
	let lastResult = null;

	const memoizedFunc = (...args) => {
		if (!arraysAreEqual(args, lastArgs)) {
			lastArgs = args;
			lastResult = func(...args);
		}
		return lastResult;
	};

	return memoizedFunc;
};
