import styled from 'styled-components';
import {Icon} from '../../../Elements/Icon/Icon';
import {withUnit, media} from '../../../../styles/utils';
import {colors, sizes} from '../../../../styles/variables';
import {ImageNonMobile} from '../../../Elements/Image/Image';
import {HeadlineStyledH3, HeadlineStyledH5} from '../../../Elements/Headline/styles';
import Teaser from '../../../Elements/Teaser/Teaser';

export const LinkToAllSearchResultsStyled = styled.a`
	text-align: center;
	cursor: pointer;
	display: block;
`;

export const IconStyled = styled(Icon)`
	cursor: pointer;
	pointer-events: auto;
	line-height: 1em;

	width: 1em;
	height: 1em;

	padding: 0 ${withUnit(sizes.distance.base / 2)};

	svg {
		width: 1em;
		height: 1em;
	}

	svg {
		pointer-events: none;
		fill: ${colors.carrot};
	}

	&:hover,
	&:focus {
		svg {
			fill: ${colors.candy};
		}
	}
`;

export const SearchResultEntryType1Styled = styled.div`
	margin-bottom: 2em;
	padding: 0.6em 0;
`;

export const SearchResultEntryType2Styled = styled.div`
	margin-bottom: 0.7em;
`;

// we style the 'alt'-text that is shown when the image could not be loaded:
// make the font smaller (otherwise it would stand out with 22px),
// alternatively hide it by coloring it white (like the background);
// the 'alt' text is identical to the title, so no information is lost
export const ImageStyled = styled(ImageNonMobile)`
	font-size: 12px;
	color: white;

	box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 20px;
`;

export const LabelType1Styled = styled.div`
	font-size: 0.8em;
`;

export const LabelType2Styled = styled.div`
	font-size: 0.6em;
	font-weight: 800;
`;

export const HeadlineType1Styled = styled(HeadlineStyledH5)`
	margin-bottom: 0;
`;

export const HeadlineType2Styled = styled(HeadlineStyledH5)`
	font-size: 0.9em;
	font-weight: 500;
	margin-bottom: 0;
`;

export const HeadlineStyled = styled(HeadlineStyledH3)``;

export const FacetHeadlineStyled = styled(HeadlineStyledH3)`
	font-weight: 200;
	font-size: 1em;
	margin-bottom: ${withUnit(sizes.distance.base)};

	${media.md`
		font-size: 0.9em;
		margin-bottom: ${withUnit(sizes.distance.base * 2)};
	`};
`;

export const SearchResultFacetsStyled = styled.div`
	margin-top: ${withUnit(sizes.distance.base)};

	${media.md`
		margin-top: ${withUnit(sizes.distance.base * 2)};
	`};

	a {
		&,
		&:visited,
		&:focus,
		&:active,
		&:hover {
			color: ${colors.black};
		}
	}
`;

export const TeaserStyled = styled(Teaser)`
	max-width: 450px;
`;
