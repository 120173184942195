import {connect} from 'react-redux';

import styled from 'styled-components';
import {initialSearch} from '../../../../../state/actions/search';
import SearchForm from '../../SearchForm/SearchForm';

const SearchFormStyled = styled(SearchForm)`
	margin: 0 auto;
	max-width: 400px;
	width: 100%;
`;

const mapStateToProps = state => ({
	searchTerm: state.initialSearch.searchTerm
});

const mapDispatchToProps = dispatch => ({
	onSearchRequest: searchTerm => dispatch(initialSearch(searchTerm))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchFormStyled);
