import styled from 'styled-components';
import {sizes} from '../../../styles/variables';
import {media, withUnit} from '../../../styles/utils';

export const ContainerStyled = styled.div`
	box-sizing: border-box;
	display: flex;
	padding: ${withUnit(sizes.distance.base / 4)};

	${media.lg`
		padding: ${withUnit(sizes.distance.base / 2)};
	`};
`;

export const RowStyled = styled.div`
	box-sizing: border-box;
	display: flex;
	flex: 0 1 auto;
	margin: -${withUnit(sizes.distance.base / 4)};
	flex-wrap: wrap;

	${media.lg`
		margin: -${withUnit(sizes.distance.base / 2)};
	`};

	${(props) => (props.styles && props.styles.padding ? `padding: ${props.styles.padding};` : ``)};
	${(props) => (props.styles && props.styles.marginBottom ? `margin-bottom: ${withUnit(sizes.distance.base)};` : ``)};
	${(props) => (props.styles && props.styles.alignItems ? `align-items: ${props.styles.alignItems};` : ``)};
	${(props) =>
		props.styles && props.styles.justifyContent ? `justify-content: ${props.styles.justifyContent};` : ``};
`;

export const ColumnStyled = styled.div`
	box-sizing: border-box;
	padding: ${withUnit(sizes.distance.base / 4)};
	width: 100%;
	position: relative;

	${media.lg`
		padding: ${withUnit(sizes.distance.base / 2)};
	`};

	${media.xxs`
		${(props) => (props.xxs ? `width: ${(props.xxs / 12) * 100}%;` : ``)};
		${(props) => (props.offset.xxs ? `margin-left: ${(props.offset.xxs / 12) * 100}%;` : ``)};
	`};

	${media.xs`
		${(props) => (props.xs ? `width: ${(props.xs / 12) * 100}%;` : ``)};
		${(props) => (props.offset.xs ? `margin-left: ${(props.offset.xs / 12) * 100}%;` : ``)};
	`};

	${media.sm`
		${(props) => (props.sm ? `width: ${(props.sm / 12) * 100}%;` : ``)};
		${(props) => (props.offset.sm ? `margin-left: ${(props.offset.sm / 12) * 100}%;` : ``)};
	`};

	${media.md`
		${(props) => (props.md ? `width: ${(props.md / 12) * 100}%;` : ``)};
		${(props) => (props.offset.md ? `margin-left: ${(props.offset.md / 12) * 100}%;` : ``)};
	`};

	${media.lg`
		${(props) => (props.lg ? `width: ${(props.lg / 12) * 100}%;` : ``)};
		${(props) => (props.offset.lg ? `margin-left: ${(props.offset.lg / 12) * 100}%;` : ``)};
	`};

	${media.xl`
		${(props) => (props.xl ? `width: ${(props.xl / 12) * 100}%;` : ``)};
		${(props) => (props.offset.xl ? `margin-left: ${(props.offset.xl / 12) * 100}%;` : ``)};
	`};

	${media.xxl`
		${(props) => (props.xxl ? `width: ${(props.xxl / 12) * 100}%;` : ``)};
		${(props) => (props.offset.xxl ? `margin-left: ${(props.offset.xxl / 12) * 100}%;` : ``)};
	`};
`;
