import React from 'react';
import PropTypes from 'prop-types';
import Row from '../../../../Layout/Grid/Row';
import Column from '../../../../Layout/Grid/Column';
import LinkToAllSearchResults from './LinkToAllSearchResults';
import {SearchResultFacet} from './SearchResultFacet';
import {FacetHeadlineStyled, SearchResultFacetsStyled} from '../styles';

const SearchResultFacets = ({entries, count}) => {
	let xlCols = null;
	Object.keys(entries).length > 3 ? xlCols = 3 : xlCols = 4
	return (
		<SearchResultFacetsStyled>
			<Row>
				{Object.entries(entries).map(([title, entry]) => {
					const {urls} = entry;

					return (
						<Column key={title} sm={6} xl={xlCols}>
							<FacetHeadlineStyled>{title}</FacetHeadlineStyled>
							<SearchResultFacet title={title} list={urls} />
						</Column>
					);
				})}
			</Row>
			{count > 7 && <LinkToAllSearchResults count={count} />}
		</SearchResultFacetsStyled>
	);
};

SearchResultFacets.propTypes = {
	entries: PropTypes.objectOf(PropTypes.object),
	count: PropTypes.number
};

SearchResultFacets.defaultProps = {
	entries: null,
	count: 0
};

export default SearchResultFacets;
