import styled from 'styled-components';
import Link from '../../../../Elements/Link/Link';

import {withUnit, media} from '../../../../../styles/utils';
import {colors, sizes} from '../../../../../styles/variables';
import {HeadlineStyledH4} from '../../../../Elements/Headline/styles';

export const SearchResultsSidebarStyled = styled.div`
	display: none;

	${media.lg`
		display: block;
	`};
`;

export const LinkStyled = styled(Link)`
	color: ${colors.black};
`;

export const SidebarItemsStyled = styled.ul`
	border-top: 2px solid ${colors.snow};
`;

export const SidebarItemStyled = styled.li`
	border-bottom: 2px solid ${colors.snow};
`;

export const SidebarItemLink = styled(Link)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${colors.black};
	transition: color 0.3s ease;
	${props => (props.active ? `color: ${colors.carrot}; font-weight: 700;` : '')};
	font-size: 0.8em;
	font-weight: ${({isSelected}) => (isSelected ? 600 : 400)};
	padding: 0.8em 0;
	cursor: pointer;

	&:hover {
		color: ${props => (props.href || props.onClick ? colors.carrot : '')};
	}
`;

export const HeadlineStyled = styled(HeadlineStyledH4)`
	font-weight: 800;
`;

export const FullSearchResultsStyled = styled.div`
	margin-top: ${withUnit(sizes.distance.base)};
	margin-bottom: ${withUnit(sizes.distance.base)};
`;
