import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Layout from '../components/Layout/Layout';

import InitialSearch from '../components/Container/Search/InitialSearch/InitialSearch';
import FullSearch from '../components/Container/Search/FullSearch/FullSearch';

// component

const SearchPage = ({location, showFullSearch}) => (
	<Layout location={location}>{showFullSearch ? <FullSearch /> : <InitialSearch />}</Layout>
);

SearchPage.propTypes = {
	location: PropTypes.shape({pathname: PropTypes.string}).isRequired,
	showFullSearch: PropTypes.bool
};

SearchPage.defaultProps = {
	showFullSearch: false
};

// connect with redux

const mapStateToProps = state => ({
	showFullSearch: !!state.fullSearch.results
});

export default connect(mapStateToProps)(SearchPage);
